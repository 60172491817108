<template>
  <div class="order_in_work" v-fancy="Order">
    <div class="order_title">
      Расчёт <span class="color_accent">#{{ Order.oNumber }}</span> от
      {{ Order.oDate }}
    </div>
    <div class="order_areas">
      <div
        v-if="Order.images !== undefined && Order.images.length > 0"
        class="block_area area_maket block_maket"
      >
        <div class="title_block_maket">Файлы макета:</div>
        <div class="slider_maket_image">
          <div
            class="owl-carousel"
            :id="Order.oNumber"
            v-callback="Order.oNumber"
          >
            <a
              v-for="(path, j) in Order.images"
              :key="j"
              :href="path"
              :data-fancybox="'gallery' + Order.oNumber"
            >
              <img :src="path" alt="" class="slide-image" />
            </a>
          </div>
        </div>
        <div class="block_maket_btn">
          <div>
            <el-button
              size="medium"
              plain
              title="Скачать файлы макета"
              type="info"
              icon="el-icon-lock"
              style="width: 150px"
              :disabled="true"
              >Скачать</el-button
            >
          </div>
          <div>
            <el-button
              size="medium"
              plain
              title="Редактировать макет"
              type="info"
              icon="el-icon-lock"
              style="width: 150px"
              :disabled="true"
              >Редактировать</el-button
            >
          </div>
        </div>
      </div>
      <div
        v-else-if="
          Order.images !== undefined &&
          Order.images.length === 0 &&
          Order.files !== undefined &&
          Order.files.length > 0
        "
        class="block_area area_maket block_files_maket"
      >
        <div class="title_files_block">Файлы макета:</div>
        <div class="files_maket">
          <p v-for="file in Order.files" :key="file.fileid">
            <a
              :href="file.filelink"
              class="file_link"
              download
              :title="`Скачать файл ${file.filename}`"
              target="_blank"
              ><i class="el-icon-document"></i
              >{{ file.filename | truncate(21, "...") }}</a
            >
          </p>
        </div>
        <div
          v-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length > 0
          "
          class="files_maket_btn"
        >
          <el-button
            size="medium"
            plain
            title="Загрузить еще файлы макета"
            :disabled="true"
            type="info"
            icon="el-icon-lock"
            style="width: 150px"
            >Загрузить еще</el-button
          >
        </div>
      </div>
      <div v-else class="block_area area_maket download_maket">
        <div class="download_maket_icon"></div>
        <div class="download_maket_title">
          <p>Загрузите Ваш</p>
          <p>макет для авто проверки</p>
        </div>
      </div>
      <div class="block_area area_upload-layout">
        <div
          v-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length > 0
          "
          class="block_upload_layout_btn"
        >
          <el-button
            size="medium"
            slot="reference"
            plain
            title="Удалить файлы макета"
            type="info"
            icon="el-icon-lock"
            style="width: 150px"
            :disabled="true"
            >Удалить файлы</el-button
          >
        </div>
        <div
          v-else-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length === 0
          "
          class="block_upload_layout"
        >
          <p>Загрузка макета</p>
          <p class="color_accent">БЕЗ авто проверки</p>
        </div>
        <div v-else class="block_upload_layout_btn">
          <el-button
            size="medium"
            slot="reference"
            plain
            title="Удалить файлы макета"
            type="info"
            icon="el-icon-lock"
            style="width: 150px"
            :disabled="true"
            >Удалить файлы</el-button
          >
        </div>
      </div>
      <div class="block_area area_name">
        <div class="block_name">
          <p>Продукт: {{ Order.oName }}</p>
        </div>
      </div>
      <div class="block_area area_desc">
        <div class="order_description_block">
          <div
            class="order_description"
            v-for="(desc, i) in Order.oDesc"
            :key="i"
          >
            <p class="font_bold">{{ desc.title }}</p>
            <p v-for="(item, i) in desc.data" :key="i">{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="block_area area_note area_note_grid">
        <div class="block_note">
          <p v-if="this.Note !== ''">Примечание: {{ this.Note }}</p>
          <div v-else>
            <p>Примечания к заказу нет...</p>
          </div>
        </div>
        <div class="btn_edit_block_blocked">
          <i class="el-icon-tickets icon_blocked_edit" title="Примечание"></i>
        </div>
      </div>
      <div
        class="block_area area_receipt delivery_method_info_block"
        v-show="Order.receive !== undefined"
      >
        <div class="info_block_method">
          <p class="bold">{{ Order.receive.name }}:</p>
          <p style="margin: 8px">{{ Order.receive.address }}</p>
        </div>
        <div class="info_block_time">
          <p>Заказ будет готов</p>
          <p class="bold border_bottom_accent">{{ ReceiveDate }}</p>
        </div>
        <div class="info_block3">
          <el-button
            size="medium"
            plain
            type="info"
            icon="el-icon-lock"
            :disabled="true"
            title="Изменить способ получения заказа"
            style="width: 150px"
            >Изменить</el-button
          >
        </div>
      </div>
      <div class="block_area area_customer order_customer_payment">
        <div class="order_customer_payment_desc">
          <p class="bold">{{ Order.oCustomer }}:</p>
          <div
            v-if="Order.oCustomerCompany !== ''"
            class="order_customer_company"
          >
            <p
              v-for="(contact, j) in this.company(Order.oCustomerCompany)"
              :key="j"
            >
              {{ contact }}
            </p>
          </div>
          <div v-else class="order_customer_company">
            <p>Юр.лицо не выбрано</p>
          </div>
        </div>
        <div class="order_customer">
          <el-button
            size="medium"
            plain
            type="info"
            icon="el-icon-lock"
            :disabled="true"
            title="Изменить способ получения заказа"
            style="width: 150px"
            >Изменить</el-button
          >
        </div>
      </div>
      <div class="block_area area_promo-activate order_promo_activated">
        <div class="order_promo_activate_left"><p>%</p></div>
        <div class="order_promo_activate_center">
          <p>
            <span class="color_accent">Промокод</span> или монеты
            <span class="color_accent">«GoodCoin»</span> не использовались
          </p>
        </div>
      </div>
      <div class="block_area area_status documents_payment_status">
        <div class="documents_title">
          <p>Документы</p>
          <p>к заказу:</p>
        </div>
        <div class="documents_download">
          <a
            v-if="Order.oCustomerCompany !== '' && Order.invoice !== undefined"
            :href="Order.invoice"
            download
            target="_blank"
            class=""
            ><div class="icon_downloadpdf"></div>
            <p class="documents_download_title">Скачать счет</p></a
          >
        </div>
        <div
          :class="[
            Order.IsPay ? 'payment_status_success' : 'payment_status_nosuccess',
          ]"
        >
          <p>{{ Order.IsPay ? "Оплачен" : "Не оплачен" }}</p>
        </div>
      </div>
      <div class="block_area area_amount_and_status">
        <div class="block_amount_and_status">
          <div class="block_amount_and_status_title">
            <p>Сумма и статус:</p>
          </div>
          <div class="block_amount_and_status_info">
            <div class="block_amount_info period_active">
              <p class="bold">{{ Order.oSumm }} рублей.</p>
              <p>В {{ ReceiveDateFormat }}</p>
            </div>
            <div class="block_status_info">
              <p>Изделие</p>
              <p>изготавливается</p>
            </div>
          </div>
        </div>
      </div>
      <div class="block_area area_status_btn">
        <div class="block_status_btn">
          <div class="status_btn">В работе</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/owl.carousel";
import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";

import moment from "moment/dist/moment";
export default {
  name: "OrderInWork",
  data() {
    return {
      order: {
        id: 222155,
        oNumber: 65654,
        images: [
          "https://im0-tub-ru.yandex.net/i?id=72c71c8079ffcb230eabd444ab43b5d4&n=13&exp=1",
          "https://thumbs.dreamstime.com/b/%D1%80%D0%BE%D0%B1%D0%BE%D1%82-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D1%8F-%D0%B8%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D1%8B%D0%B9-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82-%D0%B2-%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%BC-%D0%BC%D0%BE%D0%B7%D0%B3%D0%B5-d-ren-131825004.jpg",
          "https://im0-tub-ru.yandex.net/i?id=68b355175541e56eb35bb520afc14942&n=13&exp=1",
        ],
        comments: [
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
        ],
        coinsBalance: 5000,
        coinsMaxWithdraw: 1500,
      },
    };
  },
  computed: {
    Order() {
      return this.$store.getters["Orders/Order"];
    },
    ReceiveDate() {
      if (this.Order.receive.date) {
        return moment(this.Order.receive.date).format("DD MMMM YYYY, HH:mm");
      } else {
        return "";
      }
    },
    ReceiveDateFormat() {
      if (this.Order.receive.date) {
        return moment(this.Order.receive.date).format("HH:mm DD/MM/YYYY");
      } else {
        return "";
      }
    },
    OrderGetRatio() {
      return this.$store.getters["Orders/OrderGetRatio"];
    },
    Note() {
      return this.$store.getters["Orders/OrderNote"];
    },
  },
  methods: {
    company: function (value) {
      if (value !== "") {
        return value.split(",", 2);
      } else return "";
    },
  },
  directives: {
    callback: {
      inserted: function (el, binding) {
        const $ = window.$;
        if (binding.value) {
          $("#" + binding.value).owlCarousel({
            center: true,
            items: 1,
            loop: true,
            stagePadding: 50,
            autoWidth: true,
            dots: false,
            nav: true,
            navText: ['<i class="icon-prev"></i>', '<i class="icon-next"></i>'],
          });
        }
      },
    },
    fancy: {
      inserted: function (el, binding) {
        const $ = window.$;
        if (binding.value) {
          $("[data-fancybox]").fancybox({
            loop: true,
            infobar: false,
            animationEffect: "zoom",
            animationDuration: 600,
            transitionEffect: "circular",
            transitionDuration: 400,
            slideShow: false,
            fullScreen: true,
            thumbs: false,
            closeBtn: true,
            scrolling: "yes",
          });
        }
      },
    },
  },
};
</script>

<style lang="sass"></style>
