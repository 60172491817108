<template>
  <div class="order_created" v-show="Order.oNumber">
    <div class="order_title">
      Расчёт <span class="color_accent">#{{ Order.oNumber }}</span> от
      {{ Order.oDate }}
      <el-popconfirm
        confirm-button-text="Да"
        cancel-button-text="Нет"
        icon="el-icon-delete"
        icon-color="red"
        title="Вы действидельно хотите удалить заказ?"
        @confirm="deleteOrder(Order.oid)"
      >
        <i
          class="el-icon-delete icon_hover_delete"
          slot="reference"
          title="Удалить заказ"
        ></i>
      </el-popconfirm>
    </div>
    <div class="order_areas">
      <div
        v-if="Order.images !== undefined && Order.images.length > 0"
        class="block_area area_maket block_maket"
      >
        <div class="title_block_maket">Файлы макета:</div>
        <div class="slider_maket_image">
          <div
            class="owl-carousel"
            :id="Order.oNumber"
            v-callback="Order.oNumber"
          >
            <a
              v-for="(path, j) in Order.images"
              :key="j"
              :href="path"
              :data-fancybox="'gallery' + Order.oNumber"
            >
              <img :src="path" alt="" class="slide-image" />
            </a>
          </div>
        </div>
        <div class="block_maket_btn">
          <div>
            <el-button
              size="medium"
              plain
              title="Скачать файлы макета"
              type="info"
              icon="el-icon-upload"
              style="width: 150px"
              :disabled="true"
              >Скачать</el-button
            >
          </div>
          <div>
            <el-button
              size="medium"
              plain
              title="Редактировать макет"
              type="success"
              icon="el-icon-edit"
              style="width: 150px"
              @click="goToMainCcEdit"
              >Редактировать</el-button
            >
          </div>
        </div>
      </div>
      <div
        v-else-if="
          Order.images !== undefined &&
          Order.images.length === 0 &&
          Order.files !== undefined &&
          Order.files.length > 0
        "
        class="block_area area_maket block_files_maket"
      >
        <div class="title_files_block">Файлы макета:</div>
        <div class="files_maket">
          <p v-for="file in Order.files" :key="file.fileid">
            <a
              :href="file.filelink"
              class="file_link"
              download
              :title="`Скачать файл ${file.filename}`"
              target="_blank"
              ><i class="el-icon-document"></i
              >{{ file.filename | truncate(21, "...") }}</a
            >
          </p>
        </div>
        <div
          v-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length > 0
          "
          class="files_maket_btn"
        >
          <el-button
            size="medium"
            plain
            title="Загрузить еще файлы макета"
            @click="showModalUploadLayout"
            type="success"
            icon="el-icon-download"
            style="width: 150px"
            >Загрузить еще</el-button
          >
        </div>
      </div>
      <div
        v-else
        class="block_area area_maket download_maket"
        @click="goToMainCcAdd"
      >
        <div class="download_maket_icon"></div>
        <div class="download_maket_title">
          <p>Загрузите Ваш</p>
          <p>макет для авто проверки</p>
        </div>
      </div>
      <div class="block_area area_upload-layout">
        <div
          v-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length > 0
          "
          class="block_upload_layout_btn"
        >
          <el-popconfirm
            placement="bottom"
            confirm-button-text="Да"
            cancel-button-text="Нет"
            icon="el-icon-delete"
            icon-color="red"
            title="Вы действидельно хотите удалить файлы макета?"
            @confirm="deleteFiles(Order.files)"
          >
            <el-button
              size="medium"
              slot="reference"
              plain
              title="Удалить файлы макета"
              type="primary"
              icon="el-icon-delete"
              style="width: 150px"
              >Удалить файлы</el-button
            >
          </el-popconfirm>
        </div>
        <div
          v-else-if="
            Order.images !== undefined &&
            Order.images.length === 0 &&
            Order.files !== undefined &&
            Order.files.length === 0
          "
          class="block_upload_layout"
          @click="showModalUploadLayout"
        >
          <p>Загрузка макета</p>
          <p class="color_accent">БЕЗ авто проверки</p>
        </div>
        <div v-else class="block_upload_layout_btn">
          <el-popconfirm
            placement="bottom"
            confirm-button-text="Да"
            cancel-button-text="Нет"
            icon="el-icon-delete"
            icon-color="red"
            title="Вы действидельно хотите удалить файлы макета?"
            @confirm="deleteLinks"
          >
            <el-button
              size="medium"
              slot="reference"
              plain
              title="Удалить файлы макета"
              type="primary"
              icon="el-icon-delete"
              style="width: 150px"
              >Удалить файлы</el-button
            >
          </el-popconfirm>
        </div>
      </div>
      <div class="block_area area_name">
        <div class="block_name">
          <p>Продукт: {{ Order.oName }}</p>
        </div>
      </div>
      <div class="block_area area_desc">
        <div class="order_description_block">
          <div
            class="order_description"
            v-for="(desc, i) in Order.oDesc"
            :key="i"
          >
            <p class="font_bold">{{ desc.title }}</p>
            <p v-for="(item, i) in desc.data" :key="i">{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="block_area area_note area_note_grid">
        <div class="block_note">
          <p v-if="this.Note !== ''">Примечание: {{ this.Note }}</p>
          <div v-else>
            <p>Примечания к заказу нет...</p>
            <p>Вы можете его добавить.</p>
          </div>
        </div>
        <div class="btn_edit_block">
          <i
            class="el-icon-tickets icon_hover_edit"
            @click="showModalEditNote"
            title="Редактировать примечание"
          ></i>
        </div>
      </div>
      <div
        class="block_area area_receipt delivery_method_info_block"
        v-show="Order.receive !== undefined"
      >
        <div class="info_block_method">
          <p class="bold">{{ Order.receive.name }}:</p>
          <p style="margin: 8px">{{ Order.receive.address }}</p>
        </div>
        <div class="info_block_time">
          <!--          <p>Заказ будет готов</p>-->
          <!--          <p class="bold border_bottom_accent">{{ ReceiveDate }}</p>-->
        </div>
        <div class="info_block3">
          <el-button
            size="medium"
            plain
            @click="showModalMethodReceivingOrder"
            type="info"
            icon="el-icon-location-outline"
            :disabled="Order.IsPay"
            title="Изменить способ получения заказа"
            style="width: 150px"
            >Изменить</el-button
          >
        </div>
      </div>
      <div
        class="block_area area_status-crm block_status_crm"
        title="Находится в разработке"
      >
        <div class="block_status_crm_header">
          <span>Статус в miniCRM:</span>
          <span class="link_comments" @click="showModalCommentsSrm"
            >Коммент</span
          >
        </div>
        <div class="block_status_crm_indicators">
          <div class="indicator indicator_grey"></div>
          <div class="indicator indicator_orange indicator_active"></div>
          <div class="indicator indicator_light_yellow"></div>
          <div class="indicator indicator_dark_yellow"></div>
          <div class="indicator indicator_green"></div>
        </div>
      </div>
      <div class="block_area area_customer order_customer_payment">
        <div class="order_customer_payment_desc">
          <p class="bold">{{ Order.oCustomer }}:</p>
          <div
            v-if="Order.oCustomerCompany !== ''"
            class="order_customer_company"
          >
            <p
              v-for="(contact, j) in this.company(Order.oCustomerCompany)"
              :key="j"
            >
              {{ contact }}
            </p>
          </div>
          <div v-else class="order_customer_company">
            <p>Юр.лицо не выбрано</p>
          </div>
        </div>
        <div class="order_customer">
          <!--          <span-->
          <!--            class="order_customer_link"-->
          <!--            :class="[Order.IsPay ? 'icon-lock' : '']"-->
          <!--            @click="showChangeCustomer"-->
          <!--            :title="-->
          <!--              Order.oCustomerCompany === ''-->
          <!--                ? 'Выбрать заказчика'-->
          <!--                : 'Изменить заказчика'-->
          <!--            "-->
          <!--            >{{-->
          <!--              Order.oCustomerCompany === ""-->
          <!--                ? "Выбрать заказчика"-->
          <!--                : "Изменить заказчика"-->
          <!--            }}</span-->
          <!--          >-->
          <el-button
            size="medium"
            plain
            @click="showChangeCustomer"
            type="info"
            icon="el-icon-s-custom"
            :disabled="Order.IsPay"
            style="width: 150px"
            :title="
              Order.oCustomerCompany === ''
                ? 'Выбрать заказчика'
                : 'Изменить заказчика'
            "
            >{{
              Order.oCustomerCompany === "" ? "Выбрать" : "Изменить"
            }}</el-button
          >
        </div>
      </div>
      <div
        class="block_area area_promo-activate order_promo_activate"
        title="Находится в разработке"
      >
        <div class="order_promo_activate_left"><p>%</p></div>
        <div class="order_promo_activate_center">
          <p>Активировать <span class="color_accent">промокод</span></p>
          <p>использовать <span class="color_accent">«GoodCoin»</span></p>
        </div>
        <div class="order_promo_activate_right">
          <el-button
            size="medium"
            class="btn-personal-order"
            plain
            @click="showModalPromo"
            type="info"
            :disabled="true"
            title="Применить промокод или использовать «GoodCoin»"
            style="width: 110px"
            icon="el-icon-discount"
            >Применить</el-button
          >
        </div>
      </div>
      <div class="block_area area_status documents_payment_status">
        <div class="documents_title">
          <p>Документы</p>
          <p>к заказу:</p>
        </div>
        <div class="documents_download">
          <a
            v-if="Order.oCustomerCompany !== '' && Order.invoice !== undefined"
            :href="Order.invoice"
            download
            target="_blank"
            class=""
            ><div class="icon_downloadpdf"></div>
            <p class="documents_download_title">Скачать счет</p></a
          >
        </div>
        <div
          :class="[
            Order.IsPay ? 'payment_status_success' : 'payment_status_nosuccess',
          ]"
        >
          <p>{{ Order.IsPay ? "Оплачен" : "Не оплачен" }}</p>
        </div>
      </div>
      <div class="block_area area_cost">
        <div class="block_cost">
          <div class="block_cost_title">
            <p>Стоимость и срок</p>
            <p>изготовления:</p>
          </div>
          <div class="completion_period_preloader">
            <!--            <div-->
            <!--              class="block_cost_completion_period"-->
            <!--              v-for="ratio in OrderGetRatio"-->
            <!--              :key="ratio.id"-->
            <!--              :class="{ period_active: ratio.selected }"-->
            <!--              @click="selectOrderRatio(ratio.id)"-->
            <!--            >-->
            <!--              <p>{{ ratio.name }}</p>-->
            <!--              <p class="bold">{{ ratio.summ }} рублей.</p>-->
            <!--              <p>В {{ ratio.date }}</p>-->
            <!--            </div>-->
            <div class="block_cost_completion_period period_active">
              <p>Стоимость заказа</p>
              <p class="bold">
                {{ Number(Order.oSumm) | numeral("0,0.00") }} рублей.
              </p>
              <p>Заказ будет готов</p>
              <p>в {{ ReceiveDateFormat }}</p>
            </div>
          </div>
          <el-button
            size="medium"
            plain
            @click="toWork(Order.oid)"
            type="success"
            icon="el-icon-s-promotion"
            :disabled="false"
            title="Запустить в работу"
            style="width: 150px"
            :loading="loadingToWork"
            >В работу</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/dist/moment";

export default {
  name: "OrderCreated",
  data() {
    return {
      order: {
        id: 555,
        comments: [
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинацией",
            date: "10.04.2021 10:56",
          },
          {
            item: "Визитные карточки с глянцевой ламинацией и еще какой-то там ламинациейламинац иейламинациейл аминацией , ламинац иейламинациейламинациейл аминацией , ламинац иейламинац иейламинациейл аминацией",
            date: "10.04.2021 10:56",
          },
        ],
        coinsBalance: 365,
        coinsMaxWithdraw: 50,
      },
      loadingToWork: false,
    };
  },
  computed: {
    Order() {
      return this.$store.getters["Orders/Order"];
    },
    ReceiveDate() {
      if (this.Order.receive.date) {
        return moment(this.Order.receive.date).format("DD MMMM YYYY, HH:mm");
      } else {
        return "";
      }
    },
    ReceiveDateFormat() {
      if (this.Order.receive.date) {
        return moment(this.Order.receive.date).format("HH:mm DD/MM/YYYY");
      } else {
        return "";
      }
    },
    OrderGetRatio() {
      return this.$store.getters["Orders/OrderGetRatio"];
    },
    Note() {
      return this.$store.getters["Orders/OrderNote"];
    },
  },
  methods: {
    goToMainCcAdd() {
      this.$router.push({
        name: "MainCcAdd",
        params: { oId: this.$route.params.oId },
      });
    },
    goToMainCcEdit() {
      this.$router.push({
        name: "MainCcEdit",
        params: { oId: this.$route.params.oId },
      });
    },
    showModalEditNote() {
      this.$emit("showModalEditNote");
    },
    showModalCommentsSrm() {
      this.$emit("showModalCommentsSrm", this.order);
    },
    company: function (value) {
      if (value !== "") {
        return value.split(",", 2);
      } else return "";
    },
    showModalPromo() {
      this.$emit("showModalPromo", this.order);
    },
    showModalChangedPeriod() {
      this.$emit("showModalChangedPeriod", this.order);
    },
    showModalUploadLayout() {
      this.$emit("showModalUploadLayout");
    },
    showModalMethodReceivingOrder() {
      this.$emit("showModalMethodReceivingOrder");
    },
    showChangeCustomer() {
      this.$emit("showChangeCustomer");
    },
    deleteOrder(oid) {
      this.$store.dispatch("Orders/deleteOrder", oid).then((response) => {
        if (response.data.status && response.data.status === 2000) {
          this.$router.push({
            name: "list_orders",
          });
          this.$notify({
            title: "Success",
            message: "Заказ успешно удален!",
            type: "success",
            offset: 85,
            duration: 6000,
          });
        } else {
          this.$notify({
            title: "Ошибка",
            message: "Произошла ошибка, заказ не удален!",
            type: "error",
            offset: 85,
            duration: 6000,
          });
        }
      });
    },
    toWork(oid) {
      this.loadingToWork = true;
      this.$store
        .dispatch("Orders/toWork", oid)
        .then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            if (response.data.response.readydate !== this.Order.readydate) {
              this.$emit("showModalChangedPeriod", response.data.response);
            }
          }
        })
        .finally(() => {
          this.loadingToWork = false;
        });
    },
    deleteFiles(files) {
      if (files.length > 0) {
        this.openLoadingDeletedFiles("Удаляем файлы...");
        let fileId = files.map((file) => {
          return file.fileid;
        });
        let request = {
          oid: this.$route.params.oId,
          fileId: fileId,
        };
        this.$store
          .dispatch("Orders/customerFileDeleteOrder", request)
          .then((response) => {
            if (
              response &&
              response.data.status &&
              response.data.status === 2000
            ) {
              this.$store
                .dispatch("Orders/Order", this.$route.params.oId)
                .finally(() => {
                  this.$notify({
                    title: "Success",
                    message: "Файлы успешно удалены!",
                    type: "success",
                    offset: 85,
                    duration: 6000,
                  });
                  this.closeLoadingDeletedFiles();
                });
            }
          });
      }
    },
    deleteLinks() {
      this.openLoadingDeletedFiles("Удаляем файлы...");
      this.$store
        .dispatch("CC/deleteLinks", this.$route.params.oId)
        .then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            this.$store
              .dispatch("Orders/Order", this.$route.params.oId)
              .finally(() => {
                this.$notify({
                  title: "Success",
                  message: "Файлы успешно удалены!",
                  type: "success",
                  offset: 85,
                  duration: 6000,
                });
                this.closeLoadingDeletedFiles();
              });
          }
        });
    },
    selectOrderRatio(ratio) {
      this.openLoading("");
      let request = {
        oid: this.$route.params.oId,
        ratio: ratio,
      };
      this.$store
        .dispatch("Orders/customerOrderSetRatio", request)
        .then((response) => {
          if (
            response &&
            response.data.status &&
            response.data.status === 2000
          ) {
            this.$store
              .dispatch("Orders/customerOrderGetRatio", this.$route.params.oId)
              .finally(() => {
                this.closeLoading();
              });
            this.$store.dispatch("Orders/getReceive", this.$route.params.oId);
            this.$store.dispatch("Orders/Order", this.$route.params.oId);
          }
        });
    },
    openLoading(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".completion_period_preloader",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(255, 255, 255)",
      });
    },
    closeLoading() {
      this.loading.close();
    },
    openLoadingDeletedFiles(text) {
      this.loading = this.$loading({
        lock: true,
        target: ".area_maket",
        text: text,
        spinner: "el-icon-loading",
        background: "rgb(255, 255, 255)",
      });
    },
    closeLoadingDeletedFiles() {
      this.loading.close();
    },
  },
  directives: {
    callback: {
      inserted: function (el, binding) {
        const $ = window.$;
        if (binding.value) {
          $("#" + binding.value).owlCarousel({
            center: true,
            items: 1,
            loop: true,
            stagePadding: 50,
            autoWidth: true,
            dots: false,
            nav: true,
            navText: ['<i class="icon-prev"></i>', '<i class="icon-next"></i>'],
          });
        }
      },
    },
    fancy: {
      inserted: function (el, binding) {
        const $ = window.$;
        if (binding.value) {
          $("[data-fancybox]").fancybox({
            loop: true,
            infobar: false,
            animationEffect: "zoom",
            animationDuration: 600,
            transitionEffect: "circular",
            transitionDuration: 400,
            slideShow: false,
            fullScreen: true,
            thumbs: false,
            closeBtn: true,
            scrolling: "yes",
          });
        }
      },
    },
  },
  mounted() {
    // this.openLoading("");
    this.$store.dispatch("Orders/getReceive", this.$route.params.oId);
    // this.$store
    //   .dispatch("Orders/customerOrderGetRatio", this.$route.params.oId)
    //   .finally(() => {
    //     this.closeLoading();
    //   });
    this.$store.dispatch("Orders/customerOrderDescGet", this.$route.params.oId);
  },
  destroyed() {
    this.$store.commit("Orders/Order", {});
    this.$store.commit("Orders/OrderNote", "");
  },
};
</script>

<style lang="sass">
.order_customer_company
  margin: 8px
.info_block3_link
  cursor: pointer
.el-button--medium
  padding: 10px!important
</style>
