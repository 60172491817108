<template>
  <div class="list_orders" :key="KeyOrdersComponent">
    <order-created
      v-if="which_component === 'Created'"
      @showModalCommentsSrm="showModalCommentsSrm"
      @showModalPromo="showModalPromo"
      @showModalUploadLayout="showModalUploadLayout"
      @showModalChangedPeriod="showModalChangedPeriod"
      @showChangeCustomer="showChangeCustomer"
      @showModalMethodReceivingOrder="showModalMethodReceivingOrder"
      @showModalEditNote="showModalEditNote"
    />
    <!--    <order-created2-->
    <!--      @showModalCommentsSrm="showModalCommentsSrm"-->
    <!--      @showModalPromo="showModalPromo"-->
    <!--      @showModalUploadLayout="showModalUploadLayout"-->
    <!--      @showModalChangedPeriod="showModalChangedPeriod"-->
    <!--      @showChangeCustomer="showChangeCustomer"-->
    <!--      @showModalMethodReceivingOrder="showModalMethodReceivingOrder"-->
    <!--    />-->
    <order-in-work v-if="which_component === 'InWork'" />
    <order-ready v-if="which_component === 'Ready'" />
    <modal
      ref="modalCommentsCrm"
      modal-body="CommentsCrm"
      :title="computedOrderId"
      :show-logo="true"
      :props-form="comments"
    >
    </modal>
    <modal
      ref="modalPromo"
      modal-body="Promo"
      title="Вы можете списать монеты"
      subtitle="или ввести промокод:"
      :show-logo="true"
      :props-form="order"
    />
    <modal
      ref="ModalUploadLayout"
      modal-body="UploadLayout"
      title="Загрузить макет"
      subtitle="БЕЗ автоматической проверки"
      :show-logo="true"
      :close-when-clicked-outside="false"
      :show-the-close-button="false"
    />
    <modal
      ref="ModalChangedPeriod"
      modal-body="ChangedPeriod"
      title="Заказ запущен в работу."
      :show-logo="true"
      :props-form="propsForm"
      :close-when-clicked-outside="false"
      :show-the-close-button="false"
    />
    <modal
      ref="ModalChangeCustomer"
      modal-body="ChangeCustomer"
      title="Выбрать заказчика "
      :show-logo="true"
      @showModalAddLegalEntity="showModalAddLegalEntity"
    />
    <modal
      ref="modalAddLegalEntity"
      modal-body="AddLegalEntity"
      title="Добавить юр.лицо"
      :show-logo="true"
    >
    </modal>
    <modal
      ref="modalMethodReceivingOrder"
      modal-body="MethodReceivingOrder"
      title="Выбрать способ получения заказа"
      :show-logo="true"
    >
    </modal>
    <modal
      ref="modalEditNote"
      modal-body="EditNote"
      title="Примечание к заказу"
      :show-logo="true"
    >
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import OrderCreated from "@/components/Order/OrderCreated";
// import OrderCreated2 from "@/components/Order/OrderCreated2";
import OrderInWork from "@/components/Order/OrderInWork";
import OrderReady from "@/components/Order/OrderInReady";
export default {
  name: "ListOrders",
  components: {
    OrderReady,
    OrderInWork,
    // OrderCreated2,
    OrderCreated,

    Modal,
  },
  data() {
    return {
      comments: [],
      orderId: 0,
      order: {},
      which_component: "",
      propsForm: {},
    };
  },
  computed: {
    computedOrderId() {
      return "Комментарии к Расчёту #" + this.orderId;
    },
    KeyOrdersComponent() {
      return this.$store.getters["Orders/KeyOrdersComponent"];
    },
  },
  methods: {
    showModalCommentsSrm: function (order) {
      this.comments = order.comments;
      this.orderId = order.id;
      this.$refs.modalCommentsCrm.show = true;
    },
    showModalPromo: function (order) {
      this.order = order;
      this.$refs.modalPromo.show = true;
    },
    showModalChangedPeriod: function (readydate) {
      this.propsForm = readydate;
      this.$refs.ModalChangedPeriod.show = true;
    },
    showModalUploadLayout: function () {
      this.$refs.ModalUploadLayout.show = true;
    },
    showChangeCustomer: function () {
      this.$refs.ModalChangeCustomer.show = true;
    },
    showModalAddLegalEntity: function () {
      this.$refs.modalAddLegalEntity.show = true;
    },
    showModalMethodReceivingOrder: function () {
      this.$refs.modalMethodReceivingOrder.show = true;
    },
    showModalEditNote: function () {
      this.$refs.modalEditNote.show = true;
    },
    init() {
      if (this.$route.params.oId) {
        this.$store
          .dispatch("Orders/Order", this.$route.params.oId)
          .then((response) => {
            if (
              response.data.status &&
              response.data.status === 2000 &&
              response.data.response.orders
            ) {
              if (response.data.response.orders.length === 1) {
                let status = response.data.response.orders[0].xstatus;
                switch (status) {
                  case "new":
                    this.which_component = "Created";
                    this.$store.dispatch("Settings/customerCompanyList");
                    break;
                  case "packag":
                    this.which_component = "Ready";
                    break;
                  default:
                    this.which_component = "InWork";
                }
              }
            }
          });
      }
    },
  },
  updated() {
    this.init();
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="sass">
@import "../assets/sass/one_order"
.list_orders
  width: 1348px
  margin: 0 auto
</style>
